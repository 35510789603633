import React from 'react'
import Layout from '../components/layout/Layout/Layout'
import { graphql } from 'gatsby'
import Details from '../components/Details/Details'
import HeroBanner from '../components/HeroBanner/HeroBanner'


// Todo Add listing data for content types other than Pages
export const query = graphql`
  query ($slug: String!) {
    datoCmsLocalBusiness(slug: {eq: $slug}) {
      title
      slug
      hoursOfBusiness {
        day
        closingHour
        closingMinute
        openingHour
        openingMinute
      }
      heroImages {
        alt
        fluid(maxWidth: 768, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
      }
      address
      id
      phone
      website
      emailAddress
      categories {
        id
        categoryName
      }
      shortDescription
    }
  }
`

const formatNameForUrl = (name) => {
  name = name.replace(/\s+/g, '-').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  return name
}

const buildBreadcrumbUrl = (slug, category) => {
  slug = slug.split("/")
  slug = "/" + slug[slug.length -1]
  category = formatNameForUrl(category)
  return "local-business/" + category + slug
}

const Business = (props) => {
  return (
    <Layout
      metaTitle={props.data.datoCmsLocalBusiness.seo ? props.data.datoCmsLocalBusiness.seo.title : props.data.datoCmsLocalBusiness.title}
      metaDescription={props.data.datoCmsLocalBusiness.seo ? props.data.datoCmsLocalBusiness.seo.description : null}
      metaImage={
        props.data.datoCmsLocalBusiness.seo && props.data.datoCmsLocalBusiness.seo.image ? props.data.datoCmsLocalBusiness.seo.image.url : null
      }
      twitterCard={
        props.data.datoCmsLocalBusiness.seo && props.data.datoCmsLocalBusiness.seo.twitterCard
          ? props.data.datoCmsLocalBusiness.seo.twitterCard
          : null
      }
      pathname={props.uri}
    >
      <HeroBanner />
      <Details
        leftOrRight='left'
        isBeach={false}
        title={props.data.datoCmsLocalBusiness.title}
        imageSrc={props.data.datoCmsLocalBusiness.heroImages[0].fluid}
        imageAlt={props.data.datoCmsLocalBusiness.heroImages[0].alt}
        imageTitle={props.data.datoCmsLocalBusiness.heroImages[0].title}
        description={props.data.datoCmsLocalBusiness.shortDescription}
        businessAddressLabel='Address'
        businessAddress={props.data.datoCmsLocalBusiness.address}
        businessPhoneLabel='Phone'
        businessPhone={props.data.datoCmsLocalBusiness.phone}
        businessHoursLabel='Hours'
        businessHours={props.data.datoCmsLocalBusiness.hoursOfBusiness}
        businessWebsiteLabel='Website'
        businessWebsite={props.data.datoCmsLocalBusiness.website}
        businessEmailLabel='Email'
        businessEmail={props.data.datoCmsLocalBusiness.emailAddress}
        breadcrumbUrl={buildBreadcrumbUrl(props.uri, props.data.datoCmsLocalBusiness.categories[0].categoryName)}
        isBusiness={true}
      />
    </Layout>
  )
}

export default Business
